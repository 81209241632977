import { classNames } from '@utils/classNames';
import React, { ChangeEventHandler } from 'react';

export default function CheckBox({
    label,
    value,
    touched,
    error,
    handleBlur,
    handleChange,
    id,
    name,
    containerClassName,
    disabled,
}: Readonly<{
    label: string;
    value: boolean;
    touched?: boolean;
    error?: string;
    handleBlur: (e: React.FocusEvent<any>) => void;
    handleChange: ChangeEventHandler<HTMLInputElement>;
    name: string;
    id: string;
    containerClassName?: string;
    disabled?: boolean;
}>) {
    return (
        <div
            className={classNames(
                'flex flex-row w-full space-x-2 items-center',
                containerClassName,
            )}
        >
            <input
                id={id}
                type="checkbox"
                name={name}
                className="text-black px-4 py-3 focus-visible:outline-black focus-visible:border-black"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={value}
                disabled={disabled}
            />
            <label htmlFor={id} className="text-xl">
                {label}
            </label>
            {error && touched && <p className="text-error">{error}</p>}
        </div>
    );
}

import {
    Navigate,
    createMemoryRouter,
    createSearchParams,
} from 'react-router-dom';
import { convertMapToRecord } from '@utils/map-record-transformer';
import { splitAtLastOccurrence } from '@utils/split-at-last-occurrence';
import EditOrganizationPage from '@pages/editOrganization';
import React from 'react';
import RegisterPage from '@pages/register';
import purpleApiAdapter from '@purple/purple-api.adapter';

export const createRouter = () => {
    const router = createMemoryRouter([
        {
            path: '/unternehmensdaten-aendern',
            element: <RegisterPage />,
        },
        {
            path: '/unternehmensdaten-editieren',
            element: <EditOrganizationPage />,
        },
        {
            path: '*',
            element: <Navigate to="/unternehmensdaten-aendern" />,
        },
    ]);

    // Purple has not decided how we are nested yet. Therefore we receive every url that ends with
    // /unternehmensdaten-aendern or /unternehmensdaten-editieren. We need to handle that here.

    const sendUrlChange = async (state: {
        location: { search: string; pathname: string };
    }) => {
        const currentPath = await purpleApiAdapter.getCurrentRoute();
        const purplePath = splitAtLastOccurrence(currentPath.path, '/')[0];
        const targetPath = `${purplePath}${state.location.pathname}`;
        console.debug(
            'sending url change from',
            currentPath.path,
            'to',
            targetPath,
            'from widget to purple',
        );
        purpleApiAdapter.sendUrlChangeToPurple(
            targetPath,
            state.location.search,
        );
    };

    router.subscribe((state) => {
        void sendUrlChange(state);
    });

    void purpleApiAdapter.getCurrentRoute().then((route) => {
        const path = route.path;
        const params = convertMapToRecord(route.queryParams);
        if (path) {
            const relevantPart = splitAtLastOccurrence(path, '/')[1];
            void router.navigate({
                pathname: decodeURI(relevantPart),
                search: `?${createSearchParams(params)}`,
            });
        }
    });

    purpleApiAdapter.addRouteListener((route) => {
        const path = route.path;
        const relevantPart = splitAtLastOccurrence(path, '/')[1];
        if (router.state.location.pathname !== relevantPart) {
            console.debug(
                'changing route from',
                router.state.location.pathname,
                'to',
                `/${decodeURI(relevantPart)}`,
                'on widget side',
            );
            const params = convertMapToRecord(route.queryParams);
            void router.navigate({
                pathname: `/${decodeURI(relevantPart)}`,
                search: `?${createSearchParams(params)}`,
            });
        }
    });

    return router;
};

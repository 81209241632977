import { injectedEnvironment } from './injected-env';
export const isTest = process.env.NODE_ENV === 'test';

export const Environment = {
    BACKEND_BASE_URL:
        injectedEnvironment['REACT_APP_BACKEND_BASE_URL'] ??
        process.env.REACT_APP_BACKEND_BASE_URL,
    AWS_ACCESS_KEY_ID: definedOrThrow('REACT_APP_AWS_ACCESS_KEY_ID'),
    AWS_SECRET_ACCESS_KEY: definedOrThrow('REACT_APP_AWS_SECRET_ACCESS_KEY'),
    AWS_FILE_BUCKET: definedOrThrow('REACT_APP_AWS_FILE_BUCKET'),
    isTest: process.env.NODE_ENV === 'test',
};

function definedOrThrow(variableName: string): string {
    const value =
        injectedEnvironment[variableName] ?? process.env[variableName];
    if (!value) {
        throw new Error(
            `Missing environment variable process.env.${variableName}, you need to provide it for the application to run.`,
        );
    }
    return value;
}

import { Environment } from '../env';
import { ErrorMessage } from '@components/errorMessage';
import { Formik } from 'formik';
import React, { useCallback } from 'react';
import TextInput from '@components/textInput';

export default function RegisterPage() {
    const [error, setError] = React.useState<string | null>(null);
    const [success, setSuccess] = React.useState<boolean>(false);
    const handleSubmit = useCallback(async (values: { email: string }) => {
        const result = await fetch(
            `${Environment.BACKEND_BASE_URL}/registerEmail`,
            {
                body: JSON.stringify(values),
                method: 'POST',
            },
        );
        if (result.ok) {
            setSuccess(true);
            setError(null);
        } else {
            const jsonResponse = await result.text();
            setError(jsonResponse || 'error');
            setSuccess(false);
        }
    }, []);
    return (
        <div>
            <h1 className="mb-4">
                Freischalten für Änderungen der Unternehmensdaten
            </h1>
            <p>
                Sie können die Daten, die auf unserer Webseite für Ihr
                Unternehmen angezeigt werden, selber verändern.
            </p>
            <p>
                Bitte geben Sie dafür die E-Mail-Adresse ein, die Sie bisher in
                Ihrer Kommunikation mit uns genutzt haben. Wir senden Ihnen
                daraufhin einen Freigabelink zum Ändern der Daten per E-Mail zu.
            </p>
            <br />
            <p>
                Wenn die verwendete E-Mail-Adresse nicht erkannt wird oder bei
                uns noch nicht hinterlegt ist, kontaktieren Sie uns bitte unter:{' '}
                <a href="mailto:aral@jdb.de" className="underline">
                    aral@jdb.de
                </a>
                .
            </p>

            <Formik
                initialValues={{ email: '' }}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values).finally(() => setSubmitting(false));
                }}
                validate={(values) => {
                    const errors: { email?: string } = {};
                    if (!values.email) {
                        errors.email = 'E-Mail darf nicht leer sein.';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                            values.email,
                        )
                    ) {
                        errors.email = 'Invalide E-Mail Adresse';
                    }
                    return errors;
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="py-16 flex flex-col">
                            <TextInput
                                label="E-Mail"
                                value={values.email}
                                touched={touched.email}
                                error={errors.email}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                id="email"
                                name="email"
                                type="email"
                            />
                            <button
                                className="bg-black text-yellow p-2 disabled:border w-60 disabled:text-disabled disabled:bg-transparent mt-4"
                                type="submit"
                                disabled={
                                    isSubmitting ||
                                    !!errors.email ||
                                    values.email === ''
                                }
                            >
                                Freigabelink anfordern
                            </button>
                            {success && <p>E-Mail erfolgreich gesendet</p>}
                        </div>
                    </form>
                )}
            </Formik>

            {error && (
                <div>
                    <ErrorMessage error={{ name: error, message: error }} />
                </div>
            )}
        </div>
    );
}

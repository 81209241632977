import { FileRejection, useDropzone } from 'react-dropzone';
import { classNames } from '@utils/classNames';
import React, { useMemo } from 'react';

export type DropzoneProps = {
    readonly onDrop: (
        acceptedFiles: File[],
        rejectedFiles: FileRejection[],
    ) => void;
    readonly maxFileSize: number;
    readonly maxFiles: number;
    readonly acceptedFileFormats?: Record<string, string[]>;
    readonly disabled?: boolean;
};

export function Dropzone({
    onDrop,
    maxFileSize,
    acceptedFileFormats,
    maxFiles,
    disabled = false,
}: DropzoneProps) {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: onDrop,
        accept: acceptedFileFormats,
        maxSize: maxFileSize,
        maxFiles,
        disabled,
    });

    const acceptedFilesString = useMemo(() => {
        if (!acceptedFileFormats) {
            return '';
        }

        const fileExtensions = Object.values(acceptedFileFormats).reduce(
            (acc, values) =>
                acc.concat(
                    values.map((value) => value.substring(1).toUpperCase()),
                ),
            [],
        );
        const formatString = fileExtensions.join(', ');
        const index = formatString.lastIndexOf(',');
        if (index >= 0) {
            return `${formatString.substring(
                0,
                index,
            )} oder ${formatString.substring(index + 1)}`;
        }
        return formatString;
    }, [acceptedFileFormats]);

    return (
        <div
            {...getRootProps()}
            className={classNames(
                isDragActive ? 'bg-white' : 'bg-disabled',
                disabled
                    ? 'cursor-not-allowed !bg-lightGrey border-grey'
                    : 'cursor-pointer border-blue',
                'flex flex-col items-center justify-center w-full max-w-full overflow-hidden rounded-lg shadow-xs p-3',
                'border border-dashed',
                'min-h-16',
            )}
            data-testid="drop-zone"
        >
            <div>
                <label
                    htmlFor="file-upload"
                    className={classNames(
                        'font-medium',
                        disabled
                            ? 'text-grey cursor-not-allowed'
                            : 'text-blue cursor-pointer',
                    )}
                >
                    <span>Zum Hochladen klicken</span>
                    <input {...getInputProps()} />
                </label>{' '}
                <span className="text-grey">oder Drag & Drop</span>
            </div>
            {acceptedFileFormats && (
                <div className="mt-0.5 text-xs text-grey">
                    <div className="flex justify-center">
                        {acceptedFilesString}
                    </div>
                </div>
            )}
        </div>
    );
}

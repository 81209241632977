import { ButtonType } from '@components/Button';
import { classNames } from '@utils/classNames';
import Dialog from '@components/modals/Dialog';
import React, { MouseEvent, useState } from 'react';

export default function TrashButton({
    onClick,
    className,
    testId,
    showConfirmation,
    confirmationMessage,
}: {
    readonly onClick: (event: MouseEvent<HTMLButtonElement>) => void;
    readonly className?: string;
    readonly testId?: string;
    readonly showConfirmation?: boolean;
    readonly confirmationMessage?: string;
}) {
    const [open, setOpen] = useState(false);
    const onClickInternal = showConfirmation
        ? (event: MouseEvent<HTMLButtonElement>) => {
              event.preventDefault();
              setOpen(true);
          }
        : onClick;

    return (
        <>
            <button
                className={classNames(
                    'flex items-center justify-center h-[22px] p-1 rounded bg-black hover:bg-hover text-yellow',
                    className,
                )}
                onClick={onClickInternal}
                data-testid={testId}
            >
                Löschen
            </button>
            {showConfirmation && (
                <Dialog
                    open={open}
                    setOpen={setOpen}
                    dismissible
                    actions={[
                        {
                            buttonProps: {
                                buttonType: ButtonType.PRIMARY,
                                onClick: onClick,
                                label: 'löschen',
                                testId: 'confirm-button',
                            },
                        },
                        {
                            buttonProps: {
                                buttonType: ButtonType.SECONDARY,
                                onClick: () => setOpen(false),
                                label: 'abbrechen',
                                testId: 'cancel-button',
                            },
                        },
                    ]}
                >
                    <div className="flex flex-col justify-center gap-6 mb-12">
                        <div className="text-center">Achtung</div>
                        <div className="text-center w-full block">
                            {confirmationMessage}
                        </div>
                    </div>
                </Dialog>
            )}
        </>
    );
}

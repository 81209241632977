import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';

const queryClient = new QueryClient();
export default function DefaultPage({ children }: React.PropsWithChildren) {
    return (
        <QueryClientProvider client={queryClient}>
            <div
                lang="de"
                className="bg-background h-full w-full text-black flex flex-col hyphens-auto break-words"
            >
                <div className="flex-grow py-8">{children}</div>
            </div>
        </QueryClientProvider>
    );
}
